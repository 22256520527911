import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostList from "../components/post-list"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark
  const tagName = edges[0].node.fields.tags.find(({ slug }) => slug === tag)
    .name
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={tagName} description={`Artículos sobre ${tagName}`} />
      <h1>Artículos sobre {tagName}</h1>
      <PostList posts={edges.map(edge => edge.node)} />
      <Link to="/tags">← Ver todas las categorías</Link>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { tags: { elemMatch: { slug: { eq: $tag } } } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            tags {
              name
              slug
            }
          }
          frontmatter {
            date(formatString: "DD [de]  MMMM, YYYY", locale: "es")
            author {
              slug
              avatar {
                childImageSharp {
                  fixed(width: 36) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              bio
              github
              linkedin
              name
              twitter
            }
            title
            description
            featured {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
